import './bootstrap';
import.meta.glob(['../images/**',]);
import playerjs from 'player.js';
import { lenisMain, mobile } from './lenis';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import intersect from '@alpinejs/intersect'

Alpine.plugin(intersect)



